@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Monoton");
@import url("https://fonts.googleapis.com/css?family=Bree+Serif");
/* Import Geez Tigrinya Fonts */
@import url("../../fonts/index.css");

.app {
  background-color: #fefdd1; }

.home-header {
  padding-top: 30px; }

.home .dnd-wrapper-bin {
  overflow: hidden;
  clear: both;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .home .dnd-wrapper-bin .dust-bin {
    padding-top: 7em;
    height: 1rem;
    width: 7rem;
    margin-left: 0.2rem;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: 2em;
    color: white;
    text-align: center;
    font-size: 1rem;
    line-height: normal;
    float: left;
    border-radius: 50%; }
    @media (max-width: 470px) {
      .home .dnd-wrapper-bin .dust-bin {
        padding-top: 3em;
        width: 4rem;
        height: 0.1rem; } }
    .home .dnd-wrapper-bin .dust-bin .tig-letter {
      font-size: 90px;
      margin-top: -92px;
      background-color: #b2ff59;
      line-height: 150px;
      margin-top: -120px;
      border-radius: 50%; }

.home .dnd-wrapper-box {
  overflow: hidden;
  clear: both;
  padding: 7rem 0 0 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media (max-width: 470px) {
    .home .dnd-wrapper-box {
      padding: 1rem 1rem 0 2rem; } }
  .home .dnd-wrapper-box .box {
    border: 1px dashed gray;
    background-color: white;
    padding: 1.5rem 3rem;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    cursor: move;
    float: left;
    font-size: 30px; }
    @media (max-width: 470px) {
      .home .dnd-wrapper-box .box {
        padding-right: 0.1rem;
        margin-right: 0rem; } }
    .home .dnd-wrapper-box .box .label {
      color: #4253af; }

.home .dnd-wrapper .example-enter {
  opacity: 0.01; }

.home .dnd-wrapper .example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.home .dnd-wrapper .example-leave {
  opacity: 1; }

.home .dnd-wrapper .example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in; }

.home .tigrinya-letters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px; }
  .home .tigrinya-letters-alphabet {
    display: flex;
    justify-content: center;
    background-color: black;
    color: white;
    font-size: 40px;
    border-color: red;
    align-content: center;
    padding-left: 30px;
    font-weight: 700; }

.home .tig-input {
  display: flex;
  flex-direction: column; }
  .home .tig-input input[type=text] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box; }
  .home .tig-input-input-field {
    display: flex;
    padding: 20px;
    width: 50%; }
  .home .tig-input-input-result {
    padding-top: 30px;
    font-size: 30px; }

.side-bar-list-item-icon {
  color: 'black'; }

.side-bar-icon {
  color: 'black'; }

.horizontal-bar {
  display: flex;
  flex-grow: 1; }
  .horizontal-bar .bar {
    margin-left: -12px;
    margin-right: 20px; }
  .horizontal-bar .lable {
    flex-grow: 1;
    padding-left: 20%; }

/*.alphabet-card {
    max-width: 260px;
    font-size: 3rem;
}*/
.alphabet-cards {
  display: flex;
  flex-wrap: wrap; }
  @media screen and (min-width: 60em) {
    .alphabet-cards .alphabet-card {
      flex: 0 1 calc(33% - 1em); }
    .alphabet-cards:after {
      content: "";
      flex: 0 1 calc(33% - 1em); } }

.alphabet-cards > * {
  margin: 1em 0; }

@keyframes shake {
  0% {
    transform: rotate(0deg); }
  20% {
    transform: rotate(5deg); }
  40% {
    transform: rotate(-5deg); }
  60% {
    transform: rotate(5deg); }
  80% {
    transform: rotate(-5deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes pop-in {
  0% {
    transform: scale(0.5, 0.5);
    opacity: 0; }
  40% {
    opacity: 1; }
  65% {
    transform: scale(1.1, 1.1); }
  100% {
    transform: scale(1, 1); } }

@keyframes pop-away {
  0% {
    transform: scale(1, 1) translate(0, 0); }
  40% {
    transform: scale(1.1, 1.1); }
  65% {
    opacity: 1; }
  99% {
    transform: scale(0.5, 0.5);
    opacity: 0; }
  100% {
    transform: translate(-10000em, -10000em); } }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

html, body {
  height: 100%; }

body {
  font-family: 'Bree Serif', serif; }

.center-child {
  background-color: #1c1b1a;
  background-image: linear-gradient(90deg, transparent 50%, #333 50%);
  background-size: 5px 5px;
  background-repeat: repeat;
  width: 100%;
  height: 100%; }

body::after {
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.01) 2%, rgba(0, 0, 0, 0.99) 99%, black 100%);
  color: #ccc;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  opacity: .9;
  pointer-events: none; }

.memory-app {
  position: relative; }

.center-child {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }

.homescreen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 4; }

.homescreen--visible {
  animation-name: pop-in;
  animation-duration: .75s;
  animation-fill-mode: forwards; }

.homescreen--hidden {
  animation-name: pop-away;
  animation-duration: .75s;
  animation-fill-mode: forwards; }

.homescreen__box {
  width: 50%;
  background-color: red;
  background: linear-gradient(to bottom, #ff3300 0%, #990000 100%);
  padding: 20px;
  text-align: center;
  border-radius: 30em;
  border: 6px solid orange; }

.homescreen__title {
  font-family: 'Monoton', cursive;
  font-size: 3vw;
  display: block;
  margin: 0;
  color: orange;
  line-height: 3vw; }

.homescreen__stats {
  text-transform: uppercase;
  font-size: 3vw;
  color: #fff;
  margin-bottom: .3em; }

.homescreen__number {
  color: #ffcc00; }

.homescreen__shuffle-button {
  font-size: 3vw;
  border-radius: 10em;
  border: 0;
  text-transform: uppercase;
  border: 2px solid white;
  color: orange;
  background-color: transparent;
  background: linear-gradient(to bottom, #ff3300 0%, #990000 100%);
  outline: 0;
  transition: all .25s;
  transition-timing-function: ease-out;
  padding-left: 7vw;
  padding-right: 7vw; }
  .homescreen__shuffle-button:hover {
    background: #990000;
    letter-spacing: .1em;
    border-color: orange; }

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

.card {
  width: 150px;
  height: 225px;
  text-align: center;
  transition: all .2s;
  perspective: 800;
  margin-left: 20px;
  margin-bottom: 20px; }
  @media (min-width: 320px) and (max-width: 480px) {
    .card {
      width: 60px;
      height: 85px; } }

.card--removed {
  animation-name: shake;
  animation-duration: .3s; }

.card--selected .card__inner,
.card--removed .card__inner {
  transform: rotatey(-180deg); }

.card--unselected .card__front {
  cursor: pointer;
  transition: box-shadow .25s; }
  .card--unselected .card__front:hover {
    box-shadow: 0px 0px 20px #ffcc00; }

.card__inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all .5s; }

.card__face {
  width: 100%;
  height: 100%;
  background-color: pink;
  position: absolute;
  border-radius: 5%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }

.card__front {
  background: repeating-linear-gradient(45deg, #606dbc, #606dbc 10px, #465298 10px, #465298 20px);
  overflow: hidden;
  z-index: 1; }
  .card__front::before {
    background: repeating-linear-gradient(310deg, black, black 10px, white 10px, white 20px);
    width: 100%;
    height: 100%;
    opacity: .1;
    content: ''; }

.card__back {
  z-index: 2;
  transform: rotatey(-180deg); }

.card__back::before {
  content: "";
  font-size: 10vw;
  display: block; }

.card__back::after {
  content: "";
  font-size: 3vw;
  color: #fff;
  display: block; }

.card--pig .card__back {
  background-color: pink; }
  .card--pig .card__back::before {
    content: "🐷"; }

.card--cactus .card__back {
  background-color: green; }
  .card--cactus .card__back::before {
    content: "🌵"; }

.card--corn .card__back {
  background-color: goldenrod; }
  .card--corn .card__back::before {
    content: "🌽"; }

.card--shroom .card__back {
  background-color: brown; }
  .card--shroom .card__back::before {
    content: "🍄"; }

.card--fish .card__back {
  background-color: blue; }
  .card--fish .card__back::before {
    content: "🐟"; }
